.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#findRoom{
  min-width: 20em;
}
.App-header {
  background-color: #282c34;
  min-height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0.2em 1em;
}
#SettingControls{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#startGame{
  margin-left: auto;
}

#loggedinUserNameInHeader{
  margin-left: auto;
}

#gameInMyRoomControls{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#LoginScreenContent{
  text-align: left;
  padding:2em;
}
#infoButton{
  margin-right: 0.5em;
}
#pendingGuests{
  margin-top:1em;
}
#guestsInRoom{
  margin-top:1em;
}
#goToMyRoom{
  margin: 1em;
}

.margin1em{
  margin:1em;
}

.teamInSettings .membersTitle{
  margin-top: 0.3em;
}
.teamInSettings{
  margin-top:1em;
  margin-bottom:1em;
}

.phaseTitle{
  text-align: center;
}
.App-header .actions{
  margin-left: auto;
  display: inline-block;

}
.App-header .actions button{
  margin: 0 0.1em;
}

#headerTitle{

}

#endGameButton{
  margin: 1em;
}

header button{
  display: inline;
}
#returnToMyRoom{
  text-align: right;
}

.TeamMembers{
  background: #F5FCFF;
  min-height: 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
}



#card{
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: xxx-large;
  margin:0.5em;
}
#SettingCelebritiesGame{
  text-align: left;
}
#results{
  text-align: left;
}
#gestsAndPendingTests{
  text-align: left;
  margin: 1em;
}

#ViewSettingCelebritiesGame ul{
  text-align: left;
}

#PreparingCelebritiesGame .content{
  text-align: left;

}
#PreparingCelebritiesGame .content button{
  margin-right:0.2em ;
  margin-top:1em ;
  margin-bottom:1em ;
}

#ForeignRoomHeader{
  margin:1em;
}
#ForeignRoomHeader .roomOwnerDisplayName{
  font-size: x-large;
  margin: 0.5em 0 0 0;
}
img.avatar{
  height: 1em;
  border-radius: 50%;
}
#ForeignRoomHeader .roomOwnerEmail{
  font-size: small;

  margin: 0.5em 0;
}
#ForeignRoomHeader .roomId{
  font-size: x-small;
}

#winners{
  font-size: xx-large;
  margin: 1em;
}
#winner{
  font-size: x-large;
  margin: 1em;

}
.yourAction{
  font-size: x-large;
}

#WhatAmIDoing{
  margin: 1em;
}
#timer{
  font-size: xx-large;
  color:#F44336
}

#game{
  padding: 2em;
}
#whoAndWhatIsDoing{
  margin:1em;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
